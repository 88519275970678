
import { EstimationClient, GarmentClient, GarmentDefaultDataClient, ProductionProcessClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class Layout extends Vue {

    canNavigate: boolean = false;

    created(){
        var saveToGarmentConfiguration = !!this.$route.meta.saveToGarmentConfiguration;

        if(saveToGarmentConfiguration)
            store.state.estimationClient = GarmentDefaultDataClient;
        else
            store.state.estimationClient = EstimationClient;

        this.checkIfNavbarIsNavigable();
    }

    @Watch('$store.state.estimationIdentifier')
    checkIfNavbarIsNavigable() {
        if(store.state.estimationIdentifier) {
            EstimationClient.getIfFinished(store.state.estimationIdentifier)
            .then(x => {
                this.canNavigate = x;
            })
        }
    }

    navigateTo(route) {
        if(!store.state.estimationIdentifier)
            return;

        this.$router.push(route);
    }

    get activeStep(){
        if(this.$route.name == "editbaseinformation" || this.$route.meta.name == "editbaseinformation") //.fullPath.indexOf("/calculator/editbaseinformation") != -1)
            return 1;
        else if(this.$route.name == "editrawmaterials" || this.$route.meta.name == "editrawmaterials") //.fullPath.indexOf("/calculator/rawmaterials") != -1)
            return 2;
        else if(this.$route.name == "editproductionprocess" || this.$route.meta.name == "editproductionprocess"){ //.fullPath.indexOf("/calculator/productionprocess") != -1){
            var process = this.$route.params.process.toString();
            var found = this.$store.state.phases.find( x => x.text == process);
            var index = this.$store.state.phases.indexOf(found);
            return index + 3;
        }
        else if(this.$route.name == "finaltransportation" || this.$route.meta.name == "finaltransportation") //.fullPath.indexOf("finaltransportation") != -1)
            return this.$store.state.phases.length + 3;
        else if(this.$route.name == "providers involved" || this.$route.meta.name == "providers involved") //.fullPath.indexOf("providers involved") != -1)
            return this.$store.state.phases.length + 4;
        else if(this.$route.name == "summary") //.fullPath.indexOf("summary") != -1)
            return this.$store.state.phases.length + 5;

        return 0;
    }
}
